.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: 'Copernicus';
    src: url(./assets/fonts/Copernicus/Copernicus-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Copernicus';
    src: url(./assets/fonts/Copernicus/Copernicus-Semibold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Copernicus';
    src: url(./assets/fonts/Copernicus/Copernicus-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Copernicus';
    src: url(./assets/fonts/Copernicus/Copernicus-Extrabold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Copernicus';
    src: url(./assets/fonts/Copernicus/Copernicus-Heavy.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Untitled-Sans';
    src: url(./assets/fonts/UntitledSans/UntitledSans-Regular.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Untitled-Sans';
    src: url(./assets/fonts/UntitledSans/UntitledSans-Medium.otf) format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Untitled-Sans';
    src: url(./assets/fonts/UntitledSans/UntitledSans-Bold.otf) format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Pitch';
    src: url(./assets/fonts/Pitch/Pitch-Light.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Pitch';
    src: url(./assets/fonts/Pitch/Pitch-Medium.otf) format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Pitch';
    src: url(./assets/fonts/Pitch/Pitch-Semibold.otf) format('opentype');
    font-weight: 600;
    font-style: normal;
}